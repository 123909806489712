import Vue from "vue"
import Router from "vue-router"
Vue.use(Router)

import { firebase } from "@/initializers/firebase.js"

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import(/* webpackChunkName: "home" */ "./views/home.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/mirror",
      name: "mirror",
      component: () => import(/* webpackChunkName: "mirror" */ "./views/mirror.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/check",
      name: "check",
      component: () => import(/* webpackChunkName: "check" */ "./views/check.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/login",
      name: "auth",
      component: () => import(/* webpackChunkName: "auth" */ "./views/auth.vue")
    },
    { path: "*", component: () => import(/* webpackChunkName: "not-found" */ "./views/404.vue") }
  ]
})

router.beforeEach(async (to, from, next) => {
  let currentUser = await firebase.auth().currentUser
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth && !currentUser) {
    next("login")
  } else {
    next()
  }
})

export default router