import Vue from "vue"
import { firestorePlugin } from "vuefire"

Vue.use(firestorePlugin)

import Firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyD9aBlL42NhRuorosyd-25kpW7o58w92cw",
  authDomain: "jaws-agnes.firebaseapp.com",
  databaseURL: "https://jaws-agnes.firebaseio.com",
  projectId: "jaws-agnes",
  storageBucket: "jaws-agnes.appspot.com",
  messagingSenderId: "805597918362",
  appId: "1:805597918362:web:c910ac39d7a37f58fedb23",
  measurementId: "G-2SMSGLTY02"
}

Firebase.initializeApp(firebaseConfig)

export const firebase = Firebase