import Vue from "vue"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome"

library.add(require("@fortawesome/free-solid-svg-icons/faBalanceScale").faBalanceScale)
library.add(require("@fortawesome/free-solid-svg-icons/faExclamationTriangle").faExclamationTriangle)
library.add(require("@fortawesome/free-solid-svg-icons/faIdCard").faIdCard)
library.add(require("@fortawesome/free-solid-svg-icons/faMinus").faMinus)
library.add(require("@fortawesome/free-solid-svg-icons/faPlus").faPlus)
library.add(require("@fortawesome/free-solid-svg-icons/faSearch").faSearch)
library.add(require("@fortawesome/free-solid-svg-icons/faShoppingBag").faShoppingBag)
library.add(require("@fortawesome/free-solid-svg-icons/faSpinner").faSpinner)
library.add(require("@fortawesome/free-solid-svg-icons/faStar").faStar)
library.add(require("@fortawesome/free-solid-svg-icons/faStarHalf").faStarHalf)
library.add(require("@fortawesome/free-solid-svg-icons/faVial").faVial)

Vue.component(FontAwesomeIcon.name, FontAwesomeIcon)
Vue.component(FontAwesomeLayers.name, FontAwesomeLayers)
