<template>
  <div id="app" class="position-relative" :key="application.cacheKey" v-if="application">
    <router-view></router-view>
  </div>
</template>

<script>
  import { firebase } from "@/initializers/firebase.js"

  export default {
    name: "frontend",
    data() {
      return {
        application: null
      }
    },
    async mounted() {
      try {
        await this.$bind("application", firebase.firestore().collection(`application`).doc(`settings`))
      } catch(error) {
        this.application = { cacheKey: Math.random().toString(36).substring(2) }
      }
    }
  }
</script>

<style lang="scss">
  #app {
    width: 100%;
    height: 100vh;
  }
</style>
