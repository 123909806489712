import Vue from "vue"
import Frontend from "./frontend.vue"
import router from "./router"

import { firebase } from "@/initializers/firebase.js"
import MouseTrap from "@/initializers/mousetrap.js"
Vue.use(MouseTrap)

import "@/frontend/initializers/font-awesome.js"
import "@/initializers/bootstrap-vue.js"
import "@/frontend/initializers/fullscreen.js"
import "@/initializers/luxon.js"

import "bootstrap"
import "@/frontend/assets/css/screen.scss"

Vue.config.productionTip = false

let app

firebase.auth().onAuthStateChanged(async () => {
  if(!app) {
    let currentUser = await firebase.auth().currentUser

    app = new Vue({
      router,
      created() {
        if(!currentUser) {
          this.$router.push({ name: "auth" })
        }
      },
      render: h => h(Frontend)
    }).$mount("#app")
  }
})
